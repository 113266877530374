import { SelectOption } from '../../models/shared.models';
import { v4 } from 'uuid';
import { GroupOption } from '../../models/groups.models';
import { PostAsset } from '../home/home.models';

export type CreatePostForm = {
    behalfOf: SelectOption;
    postID: string;
    content: string;
    image: string | undefined;
    imageURL: PostAsset | undefined;
    company: SelectOption | undefined;
    campaign?: string;
    groups: GroupOption[];
    users: SelectOption[];
    publishAtDate: Date | undefined;
    publishAtTime: string | undefined;
    type: boolean | 'none' | undefined;
    productID?: SelectOption | undefined;
};

export const createPostFormDefaultValues: CreatePostForm = {
    behalfOf: { label: '', value: '' },
    postID: v4(),
    content: '',
    imageURL: { url: '' },
    image: '',
    company: undefined,
    campaign: '',
    groups: [],
    users: [],
    publishAtDate: undefined,
    publishAtTime: '',
    type: false,
    productID: undefined,
};
