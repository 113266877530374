import { JSX, ReactElement, ReactNode } from 'react';
import { genderTranslate } from './users.utils';
import { v4 } from 'uuid';
import { FilterForm } from '../table/Tables.types';
import { SelectOption, UniqueFieldType } from '../../models/shared.models';
import { getMemberGroups } from '../../api/groups.api';
import { GroupOption } from '../../models/groups.models';
import { Purchase } from '../orders/order.models';
import { Post } from '../home/home.models';

export type UserType = 'employee' | 'HR' | 'admin';

export const UserTranslate = {
    HR: 'HR',
    employee: 'עובד',
    admin: 'מנהל',
};

export const systemAccess: Record<UserType, string> = {
    HR: 'ניהול חברה',
    employee: 'אפליקציה',
    admin: 'ניהול כללי',
};

export type Gender = 'male' | 'female' | 'none';

export const userTypeOptions: { label: string; value: UserType }[] = [
    { label: UserTranslate['HR'], value: 'HR' },
    { label: UserTranslate['employee'], value: 'employee' },
];

export const allUserTypeOptions = [...userTypeOptions, { label: 'אדמין', value: 'admin' }];

export type AddressType = 'home' | 'office';
export const addressTypes: AddressType[] = ['home', 'office'];

export type MaritalStatus =
    | 'single'
    | 'married'
    | 'divorced'
    | 'seperated'
    | 'available'
    | 'unavailable'
    | 'knownInPublic'
    | 'widow';

export const maritalStatuses: MaritalStatus[] = [
    'single',
    'married',
    'divorced',
    'seperated',
    'available',
    'unavailable',
    'knownInPublic',
    'widow',
];

export const openCoupleStatuses: MaritalStatus[] = ['married', 'knownInPublic', 'unavailable'];

export const translateMaritalStatus: Record<MaritalStatus, string> = {
    single: 'רווק',
    married: 'נשוי',
    divorced: 'גרוש',
    seperated: 'פרוד',
    available: 'פנוי',
    unavailable: 'תפוס',
    knownInPublic: 'ידוע בציבור',
    widow: 'אלמן',
};

export type Relative = {
    name?: { value: string; isPublic: boolean };
    gender?: { value: Gender; isPublic: boolean };
    birthDate?: { value: string | number | null; isPublic: boolean };
    id: string;
};

export type Address = {
    isMain?: boolean;
    type: AddressType;
    addressID: string;
    street: string;
    city: string;
    isCityPublic: boolean;
    isStreetPublic: boolean;
    floor?: number;
    apartment?: number;
    entrance?: string;
    comments?: string;
    isActive: boolean;
};

export type User = {
    userID: string;
    companyID: string | undefined;
    companyName?: string;
    firstName: string;
    lastName: string;
    userType: UserType;
    isFrozen: boolean;
    pronoun: { value: 'he' | 'she'; isPublic: boolean };
    phone: { value: string; isPublic: boolean };
    officePhone?: { value: string; isPublic: boolean };
    imageURL?: string;
    birthDate?: { value: string | number | undefined; isPublic: boolean };
    birthYear?: { value: string | number | undefined; isPublic: boolean };
    addresses: Address[];
    uniqueFields: UniqueFieldType[];
    role: { value: string; isPublic: boolean };
    jobStartDate?: { value: string | number | undefined; isPublic: boolean };
    roleStartDate?: { value: string | number | undefined; isPublic: boolean };
    department: { value: string; isPublic: boolean; _id: string };
    managerID?: { value: string; isPublic: boolean };
    managerName?: string;
    managerPhone?: string;
    isManager?: boolean;
    email?: { value: string; isPublic: boolean };
    maritalStatus?: { value?: MaritalStatus; isPublic: boolean };
    marriageDate?: { value: string | number; isPublic: boolean };
    spouse?: Relative;
    children: Relative[];
    organizationalGroups?: string[];
    personalCredits?: number;
    socialCredits?: number;
};

export type SaveUserRequest = User & {
    groupsIDs: string[];
};

export type ExcelUser = {
    companyID: string;
    First_Name: string;
    Last_Name: string;
    Pronoun: 'he' | 'she';
    Phone: string;
    Office_Phone: string;
    Image_URL: string;
    Role: string;
    Department: string;
    Email: string;
    Marital_Status: MaritalStatus;
    Role_Start_Date?: string | number;
    Job_Start_Date?: string | number;
    Birth_Date?: string | number;
    Home_City: string;
    Home_Street: string;
    Office_City: string;
    Office_Street: string;
    Manager_Phone?: string;
    Unique_Fields: Record<string, string>[];
    Company_Unique_Fields: SelectOption[];
    Spouse: { name: string; gender: string; birthDate: string | number } | string;
    Children: { name: string; gender: string; birthDate: string | number }[];
    Organizational_Groups?: string[];
    Has_Image: string;
};

export type POSSIBLE_PER_PAGE_VALUES = 25 | 50 | 100;
export type PossibleUserTypes = 'employee' | 'HR';

export type GenderLabel = 'זכר' | 'נקבה';

export type UserDetailsForm = {
    userID: string;
    firstName: string;
    lastName: string;
    userType: { label: string; value: UserType };
    isManager?: boolean;
    imageURL: string | undefined;
    url?: string;
    companyGroups: {
        label: string;
        value: { groupID: string };
    }[];
    isFrozen: boolean;
    company: { label: string; value: string | undefined };
    pronoun: { value: SelectOption; isPublic: boolean };
    phone: User['phone'];
    officePhone?: User['officePhone'];
    birthDate?: User['birthDate'];
    birthYear?: User['birthYear'];
    address: { city: string; isCityPublic: boolean; street: string; isStreetPublic: boolean };
    officeAddress: { city: string; isCityPublic: boolean; street: string; isStreetPublic: boolean };
    uniqueFields: UniqueFieldType[];
    companyUniqueFields: UniqueFieldType[];
    email: User['email'];
    role: User['role'];
    roleStartDate?: User['roleStartDate'];
    jobStartDate?: User['jobStartDate'];
    department: User['department'];
    managerID?: { value: SelectOption; isPublic: boolean };
    maritalStatus?: { value: { value?: MaritalStatus; label?: string }; isPublic?: boolean };
    marriageDate?: User['marriageDate'];
    spouse?: User['spouse'];
    children: {
        name?: { value: string; isPublic: boolean };
        gender?: { value: { label: GenderLabel; value: Gender }; isPublic: boolean };
        birthDate?: { value: string | number | null; isPublic: boolean };
        id: string;
    }[];
    personalCredits?: number;
    socialCredits?: number;
};

export const userDetailsFormInitialValues: UserDetailsForm = {
    userID: '',
    firstName: '',
    lastName: '',
    userType: { value: 'employee', label: 'עובד' },
    isFrozen: false,
    imageURL: '',
    url: '',
    company: { label: '', value: '' },
    companyGroups: [],
    pronoun: { value: { label: 'הוא', value: 'he' }, isPublic: true },
    phone: { value: '', isPublic: true },
    officePhone: { value: '', isPublic: true },
    birthDate: { value: undefined, isPublic: true },
    birthYear: { value: undefined, isPublic: false },
    address: { city: '', isCityPublic: true, street: '', isStreetPublic: false },
    officeAddress: { city: '', isCityPublic: true, street: '', isStreetPublic: true },
    uniqueFields: [],
    companyUniqueFields: [],
    email: { value: '', isPublic: true },
    role: { value: '', isPublic: true },
    department: { value: '', isPublic: true, _id: '' },
    jobStartDate: { value: undefined, isPublic: true },
    roleStartDate: { value: undefined, isPublic: true },
    managerID: { value: { value: '', label: '' }, isPublic: true },
    maritalStatus: undefined,
    marriageDate: { value: 0, isPublic: false },
    //@ts-ignore
    spouse: {
        gender: { value: 'none', isPublic: false },
        name: { value: '', isPublic: false },
        birthDate: { value: '', isPublic: false },
    },
    children: [],
};

export type BoostsFilterForm = FilterForm<Post> & {
    stats: BoostsStatsDto;
};

export type BoostsStatsDto = {
    boostsGiven: number;
    boostsReceived: number;
    creditsGiven: number;
    creditsReceived: number;
};

export type UsersFilterForm = FilterForm<User> & {
    companies?: SelectOption[];
    chosenUploadCompany: SelectOption | null;
    userType: { label: PossibleUserTypes | ''; value: PossibleUserTypes | '' } | null;
    search: string;
    userSpecialFields: string;
    companySpecialFields: string;
};

export const defaultUsersValues: UsersFilterForm = {
    companies: [],
    chosenUploadCompany: null,
    search: '',
    userSpecialFields: '',
    companySpecialFields: '',
    userType: null,
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

export const defaultBoostsValues: BoostsFilterForm = {
    stats: { boostsGiven: 0, creditsGiven: 0, boostsReceived: 0, creditsReceived: 0 },
    rowsPerPage: { label: 25, value: 25 },
    page: 0,
    orderBy: {
        direction: 'DESC',
        sortedColumn: undefined,
    },
};

export type UsersTableData = {
    fullName: ReactNode;
    id: string;
    company?: string;
    manager: string;
    userType: string;
    phone: string;
    image: ReactElement | '';
    credits: string;
    freeze: ReactElement;
    groups: string;
};

export type PostsTableData = {
    publisher: ReactElement;
    targetUserID: ReactElement;
    timestamp: string;
    boostType: ReactNode;
    productName: string;
    creditsAmount: number | string;
    content: ReactNode;
    groups: string;
    hashtags: string;
};

export type PurchasesHistoryTableData = {
    purchaseID: string;
    image: JSX.Element;
    productName: string;
    totalAmount: string;
    purchaseDate: string;
    statusChangeDate: string;
    purchaseStatus: JSX.Element;
};

export const imageFormats = ['png', 'jpeg', 'jpg'];

export const getUserFormValues = async (user: User): Promise<UserDetailsForm> => {
    const {
        userID,
        companyID,
        companyName,
        uniqueFields,
        userType,
        birthYear,
        birthDate,
        addresses,
        imageURL,
        firstName,
        lastName,
        socialCredits,
        personalCredits,
        pronoun,
        phone,
        email,
        officePhone,
        role,
        department,
        roleStartDate,
        jobStartDate,
        managerID,
        managerName,
        marriageDate,
        maritalStatus,
        children,
        isFrozen,
        spouse,
    } = user;

    const memberGroups = await getMemberGroups(userID);

    const groups: GroupOption[] = memberGroups
        ?.filter((g) => g.type === 'company')
        ?.map((g) => {
            return {
                label: g.name ?? '',
                value: {
                    groupID: g.groupID ?? v4(),
                },
            };
        });

    return {
        company: { value: companyID, label: companyName ?? '' },
        userID,
        imageURL,
        url: imageURL,
        firstName,
        lastName,
        isFrozen,
        userType: { label: UserTranslate[userType], value: userType },
        role: role ?? { value: '', isPublic: true },
        roleStartDate: {
            value: roleStartDate?.value,
            isPublic: !!roleStartDate?.isPublic,
        },
        jobStartDate: {
            value: jobStartDate?.value,
            isPublic: !!jobStartDate?.isPublic,
        },
        department: department ?? { value: '', isPublic: true },
        pronoun: {
            value: { value: pronoun?.value ?? 'he', label: pronoun?.value === 'she' ? 'היא' : 'הוא' },
            isPublic: !!pronoun?.isPublic,
        },
        phone: phone ?? { value: '', isPublic: true },
        officePhone: officePhone ?? { value: '', isPublic: true },
        birthDate: {
            value: birthDate?.value,
            isPublic: !!birthDate?.isPublic,
        },
        birthYear: {
            value: birthYear?.value,
            isPublic: !!birthYear?.isPublic,
        },
        socialCredits,
        personalCredits,
        address: addresses?.find((a) => a.isMain && a.type === 'home') ??
            addresses?.find((a) => a.type === 'home') ?? {
                city: '',
                isCityPublic: true,
                street: '',
                isStreetPublic: false,
            },
        companyGroups: groups,
        officeAddress: addresses?.find((a) => a.isMain && a.type === 'office') ??
            addresses?.find((a) => a.type === 'office') ?? {
                city: '',
                isCityPublic: true,
                street: '',
                isStreetPublic: false,
            },
        uniqueFields: uniqueFields.filter((u) => !u.isCompanyField),
        companyUniqueFields: uniqueFields.filter((u) => u.isCompanyField),
        email: email ?? { value: '', isPublic: true },
        managerID: managerID
            ? {
                  value: { label: managerName ?? '', value: managerID?.value },
                  isPublic: managerID?.isPublic ?? true,
              }
            : { isPublic: true, value: { label: '', value: '' } },
        maritalStatus: {
            value: {
                value: maritalStatus?.value,
                label: maritalStatus?.value ? translateMaritalStatus[maritalStatus?.value] : undefined,
            },
            isPublic: maritalStatus?.isPublic,
        },
        marriageDate: {
            value: marriageDate?.value ?? 0,
            isPublic: !!marriageDate?.isPublic,
        },
        spouse: {
            name: spouse?.name ?? { value: '', isPublic: false },
            //@ts-ignore
            gender: spouse?.gender ?? { value: 'none', isPublic: false },
            id: spouse?.id ?? v4(),
            birthDate: {
                value: spouse?.birthDate?.value ?? 0,
                isPublic: !!spouse?.birthDate?.isPublic,
            },
        },
        children: children?.map((c) => {
            return {
                id: c.id,
                birthDate: {
                    value: c?.birthDate?.value ?? 0,
                    isPublic: !!c?.birthDate?.isPublic,
                },
                gender: {
                    value: { label: genderTranslate(c?.gender?.value), value: c?.gender?.value ?? 'male' },
                    isPublic: !!c?.gender?.isPublic,
                },
                name: { value: c?.name?.value ?? '', isPublic: !!c?.name?.isPublic },
            };
        }),
    };
};

export const HISTORY_DAYS_TO_SHOW = 90;

export const usersTabsNames = [
    'פרטים כלליים וניהול',
    'פרטים אישיים',
    'פרטים בחברה',
    'בני משפחה',
    'בוסטים וקרדיטים',
    'היסטוריית הזמנות',
];

export type ValidUserDetailsDto = {
    firstName: string;
    lastName: string;
    phone: string;
};

export type InvalidUserDetailsDto = ValidUserDetailsDto & {
    reason: Record<string, string>[];
};

export type AddCreditsRequest = {
    companyID: string;
    userID: string;
    amount: number;
    isSocial: boolean;
};

export type UserPurchasesFilterForm = FilterForm<Purchase> & {
    purchases: Purchase[];
    search: string;
};

export const defaultPurchaseFilterForm: UserPurchasesFilterForm = {
    purchases: [],
    search: '',
    orderBy: {
        direction: 'DESC',
        sortedColumn: 'companyID',
    },
    page: 0,
    rowsPerPage: { label: 25, value: 25 },
};
