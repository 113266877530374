import { getCompaniesAPI } from '../api/companies.api';
import { Company } from '../components/companies/companies.models';
import { getUsersAPI } from '../api/users.api';
import { PossibleUserTypes, User } from '../components/users/users.models';
import { SelectOption } from '../models/shared.models';
import { getProductsAPI, getSearchProductsVariantsAPI } from '../api/store.api';
import { Product } from '../components/store/store.models';
import { getGroupsAPI } from '../api/groups.api';
import { Group, GroupOption } from '../models/groups.models';

export const companyLoadOptions = async (inputValue: string): Promise<SelectOption[]> => {
    const results = await getCompaniesAPI<Company>({ name: inputValue });
    if (!results?.data) return [];

    return results.data.map((c) => ({ label: `${c.name}`, value: c.companyID }));
};

export const userLoadOptions = async ({
    inputValue,
    companyID,
    companiesIDs,
    excludeUserID,
    excludeAdmins,
    userType,
}: {
    inputValue: string;
    companyID?: string;
    companiesIDs?: string[];
    excludeUserID?: string;
    excludeAdmins?: boolean;
    userType?: PossibleUserTypes;
}): Promise<SelectOption[]> => {
    const results = await getUsersAPI<User>({
        companyID,
        companiesIDs,
        extraFilters: { search: inputValue, userType },
    });

    if (!results?.data) return [];

    return results.data
        .filter((u) => u.userID !== excludeUserID)
        .filter((u) => {
            if (excludeAdmins) {
                return u.userType !== 'admin';
            }

            return true;
        })
        .map((u) => {
            const fullName = `${u.firstName} ${u.lastName}`;
            return {
                label: !userType ? `${fullName} - ${u.phone.value}` : fullName,
                value: u.userID,
            };
        });
};

export const productsIDLoadOptions = async (inputValue: string) => {
    if (!inputValue) return [];

    const results = await getProductsAPI<Product>({ productID: inputValue });
    if (!results?.data) return [];

    return results.data.map((c) => ({ label: c.productID, value: c.productID }));
};

export const productsNameLoadOptions = async (inputValue: string, all?: boolean) => {
    if (!inputValue) return [];

    const results = await getProductsAPI<Product>({ productName: inputValue, all });

    if (!results?.data) return [];

    return results.data.map((c) => ({ label: `${c.title}`, value: c.productID }));
};

export const variantsNameLoadOptions = async (inputValue: string) => {
    if (!inputValue) return [];
    const results = await getSearchProductsVariantsAPI(inputValue);
    if (!results) return [];

    return results.map((c) => ({ label: `${c.title}`, value: c.variant?.id }));
};

export const managerLoadOptions = async (
    companyID: string | undefined,
    inputValue: string,
    excludeID?: string
) => {
    const results = await getUsersAPI<User>({
        companyID,
        excludeID,
        extraFilters: { search: inputValue },
    });
    if (!results?.data) return [];

    return results.data.map((u) => ({
        label: `${u.firstName} ${u.lastName} - ${u.phone.value}`,
        value: u.userID,
    }));
};

export const membersLoadOptions = async (companyID: string | undefined, inputValue: string) => {
    const results = await getUsersAPI<User>({ companyID, extraFilters: { search: inputValue } });

    if (!results?.data) return [];

    return results.data.map((user) => ({
        label: `${user.firstName} ${user.lastName} - ${user.phone.value}`,
        value: user.userID,
    }));
};

export const groupsLoadOptions = async (inputValue: string): Promise<GroupOption[]> => {
    const results = await getGroupsAPI<Group>({
        name: inputValue,
    });

    if (!results?.data) return [];

    return results.data.map((u) => ({
        label: `${u.name}`,
        value: { groupID: u.groupID },
    }));
};

export const departmantLoadOptions = async (companyID: string | undefined) => {
    const results = await getUsersAPI<User>({ companyID });
    if (!results?.data) return [];

    return results.data.map((u) => ({
        label: u.department.value,
        value: u.department._id,
    }));
};
