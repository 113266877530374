import { ReactElement } from 'react';

export interface ChartComponentProps {
    width?: number | string;
    height?: number;
    xAxisProps?: any[];
    yAxisProps?: any[];
    formatTickFunction?: any;
}
export type ChartFactoryType = {
    [key: string]: (props: ChartComponentProps) => ReactElement | null;
};
export interface ChartProps {
    data: any[];
    width?: string | number;
    height?: string | number;
    type: 'bar' | 'line' | 'pie' | 'table' | 'script';
    xAxisProps?: any[];
    yAxisProps?: any[];
    formatTickFunction?: any;
}
export const COLORS = [
    '#5B45AF',
    '#1B76F2',
    '#FF1F8B',
    '#0088FE',
    '#00C49F',
    '#FFBB28',
    '#FF8042',
    '#A569BD',
    '#D9534F',
    '#5CB85C',
    '#5DADE2',
    '#F1588E',
    '#626567',
    '#C0392B',
];
