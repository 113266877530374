import { CreatePostForm } from '../create-post/create-post.models';
import { v4 } from 'uuid';
import { SelectOption } from '../../models/shared.models';

export type SendGiftForm = CreatePostForm & {
    giftType: { value: 'credit'; label: 'קרדיטים' } | { value: 'product'; label: 'מוצר' };
    creditsAmount: number | string;
    product?: SelectOption;
    companyBudget?: number;
    isEvent?: boolean;
    eventName?: string;
};

export const createPostFormDefaultValues: SendGiftForm = {
    behalfOf: { label: '', value: '' },
    postID: v4(),
    content: '',
    imageURL: { url: '' },
    image: '',
    company: undefined,
    campaign: '',
    groups: [],
    users: [],
    publishAtDate: undefined,
    publishAtTime: '',
    giftType: { value: 'credit', label: 'קרדיטים' },
    creditsAmount: 0,
    companyBudget: 0,
    isEvent: undefined,
    eventName: '',
    type: false,
};

export const chosenStepStyles = {
    boxShadow: ' 0 3px 6px 0 rgba(104, 224, 240, 0.4)',
    backgroundColor: 'var(--color-chosen)',
};

export type GiftValidation = {
    productID: string | undefined;
    creditsAmount: number | undefined;
    groups: string[] | undefined;
    users: string[];
};
