import { FC, useEffect, useState } from 'react';
import { InnerModal } from '../modal/InnerModal/InnerModal';
import { Modal } from './Modal';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CreatePost } from '../create-post/CreatePost';
import { CreatePostForm, createPostFormDefaultValues } from '../create-post/create-post.models';
import { yupResolver } from '@hookform/resolvers/yup';
import { createPostSchema } from '../create-post/create-post-validation';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCompanies } from '../../store/companies/companies.selectors';
import { PostCreate } from '../home/home.models';
import { getToBePublishedAtTimestamp } from '../../utils/utils';
import { v4 } from 'uuid';
import { useHandleBack } from '../../hooks/useHandleBack';
import { ModalIcon } from '../modal/ModalIcon';
import { AreYouSureModal } from './AreYouSure.modal';

type CreatePostModalProps = {
    onConfirm: (post: PostCreate) => void;
    closeModal: () => void;
    isOpen: boolean;
    values?: { type?: string; productID?: string; productTitle?: string };
};

export const CreatePostModal: FC<CreatePostModalProps> = ({
    closeModal,
    onConfirm,
    isOpen,
    values = undefined,
}) => {
    const createPostForm = useForm<CreatePostForm>({
        mode: 'onChange',
        defaultValues: createPostFormDefaultValues,
        resolver: yupResolver(createPostSchema),
    });
    const [closeModalOpen, setCloseModalOpen] = useState(false);
    const companies = useSelector(getCompanies);
    const currentUser = useSelector(getCurrentUser);
    const { t } = useTranslation();
    const { isDirty } = createPostForm.formState;
    useHandleBack(isDirty);

    useEffect(() => {
        if (isOpen && values) {
            createPostForm.setValue(
                'productID',
                values?.productID
                    ? {
                          label: values.productTitle as string,
                          value: values.productID,
                      }
                    : undefined
            );
            createPostForm.setValue('type', 'none');
        }
    }, [isOpen, values, createPostForm]);
    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            const currentCompany = companies.find((c) => c.companyID === currentUser?.companyID);
            if (currentCompany?.name) {
                createPostForm.setValue('company', {
                    label: currentCompany.name,
                    value: currentCompany.companyID,
                });
            }
        }
    }, [companies, currentUser?.companyID, currentUser?.userType, createPostForm]);

    useEffect(() => {
        if (isOpen) {
            createPostForm.setValue('postID', v4());
        }
    }, [isOpen]);

    const convertLinksToHTML = (content: string): string => {
        const urlRegex = /(?:https?:\/\/[^\s]+)|(?:www\.[^\s<]+)/g;

        return content.replaceAll(urlRegex, (match) => {
            if (match.startsWith('www.')) {
                return `<a href="http://${match}" target="_blank">${match}</a>`;
            } else if (match.startsWith('http')) {
                return `<a href="${match}" target="_blank">${match}</a>`;
            } else {
                return match;
            }
        });
    };

    const onPostCreate = () => {
        const postForm = createPostForm.getValues();
        const toBePublishedAt = getToBePublishedAtTimestamp(postForm.publishAtDate, postForm.publishAtTime);

        const convertedContent = `<div class="post-text">${convertLinksToHTML(postForm.content)}</div>`;
        const encoder = new TextEncoder();
        const uint8Array = encoder.encode(convertedContent);
        const byteArray = Array.from(uint8Array);

        const post: PostCreate = {
            companyID: postForm.company?.value,
            behalfOfUserID: postForm.behalfOf?.value,
            postID: postForm.postID,
            campaign: postForm.campaign,
            content: btoa(String.fromCharCode.apply(null, byteArray)),
            hashTags: [],
            relatedGroupsIDs: postForm.groups.map((g) => g.value?.groupID),
            relatedUsersIDs: postForm.users?.map((u) => u.value),
            type: postForm.type === true ? 'image' : postForm.type === false ? 'text' : 'promotedProduct',
            hiddenFromUsersID: [],
            postAssetsURLs: postForm.imageURL?.url ? [postForm.imageURL] : [],
            toBePublishedAt,
            isStyled: false,
            isBase64: true,
            productID: postForm.productID?.value,
        };

        onConfirm(post);
        createPostForm.reset();
    };

    const handleClose = () => {
        closeModal();
        createPostForm.reset();
    };

    return (
        <Modal
            isOpen={isOpen}
            onClose={() => {
                if (!isDirty) {
                    handleClose();
                } else {
                    setCloseModalOpen(true);
                }
            }}>
            <FormProvider {...createPostForm}>
                <InnerModal
                    content={<CreatePost onConfirm={onPostCreate} />}
                    header={{ title: t('createNewPost') }}
                    footer={{ buttons: [] }}
                />
            </FormProvider>
            <AreYouSureModal
                onConfirm={handleClose}
                icon={<ModalIcon theme={'ALERT'} />}
                closeModal={() => setCloseModalOpen(false)}
                title={t('leaveWithoutSave')}
                text={t('changesNotSaved')}
                isOpen={closeModalOpen}
            />
        </Modal>
    );
};
