import { FC, useCallback, useEffect, useState } from 'react';
import { BreadCrumbs } from '../breadcrumbs/BreadCrumbs';
import './users.scss';
import { ArrowLeft, ArrowRight, Home, Save } from 'react-feather';
import { UsersManagement } from './users-management';
import { FormProvider, useForm } from 'react-hook-form';
import {
    getUserFormValues,
    User,
    UserDetailsForm,
    userDetailsFormInitialValues,
    usersTabsNames,
} from './users.models';
import { Tabs } from '../tabs/Tabs';
import { Button } from '../form/buttons/Button';
import { createOrEditUser, getUsersAPI, sendSmsDownload } from '../../api/users.api';
import { UsersStep1 } from './UsersStep1';
import { UsersStep2 } from './UsersStep2';
import { UsersStep3 } from './UsersStep3';
import { UsersStep4 } from './UsersStep4';
import { UsersStep5 } from './UsersStep5';
import { UsersStep6 } from './UsersStep6';
import { usersSchema } from './user-validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 } from 'uuid';
import { buildFormUser } from './users.utils';
import { closeModal, raiseModal } from '../../store/modal/modal.actions';
import { useDispatch, useSelector } from 'react-redux';
import { ModalIcon } from '../modal/ModalIcon';
import { getCompaniesAPI } from '../../api/companies.api';
import { Company } from '../companies/companies.models';
import { setEditedCompanyUniqueFields } from '../../store/companies/companies.actions';
import { getCompanies } from '../../store/companies/companies.selectors';
import { SuccessToast } from '../toaster/Toast';
import { useHandleBack } from '../../hooks/useHandleBack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../store/users/UsersSelectors';

export const Users: FC = () => {
    const dispatch = useDispatch();
    const [currentStep, setCurrentStep] = useState(0);
    const [tabs, setTabs] = useState(usersTabsNames.slice(0, 5));
    const [editedUser, setEditedUser] = useState<User>();
    const company = useSelector(getCompanies);
    const currentUser = useSelector(getCurrentUser);
    const form = useForm<UserDetailsForm>({
        defaultValues: userDetailsFormInitialValues,
        mode: 'onTouched',
        resolver: yupResolver(usersSchema),
    });
    const {
        formState: { isDirty },
    } = form;
    const handleBack = useHandleBack(isDirty);
    const [breadCrumbs, setBreadCrumbs] = useState(['ניהול משתמשים']);
    const { t } = useTranslation();
    const { userID } = useParams();
    const navigate = useNavigate();

    const onCreateOrEdit = useCallback(
        async (clickedUserID: string | undefined) => {
            const users = await getUsersAPI<User>({ userID: clickedUserID, isExtended: true });
            const foundUser = users?.data?.[0];

            if (clickedUserID && foundUser) {
                setEditedUser(foundUser);
                const company = await getCompaniesAPI<Company>({ companyID: foundUser.companyID });
                const userCompany = company.data?.[0];

                if (userCompany) {
                    dispatch(
                        setEditedCompanyUniqueFields([
                            ...userCompany?.companyUniqueFields.map((c) => ({ ...c, isCompanyField: true })),
                            ...userCompany?.usersUniqueFields,
                        ])
                    );
                }

                form.reset(await getUserFormValues(users?.data?.[0]));

                if (foundUser?.userType !== 'admin' && currentUser?.userType === 'admin') {
                    setTabs(usersTabsNames);
                }
            } else {
                setEditedUser(undefined);
                const currentCompany = currentUser?.userType === 'admin' ? undefined : company?.[0];

                form.reset(
                    {
                        ...userDetailsFormInitialValues,
                        userID: v4(),
                        company: currentCompany && {
                            label: currentCompany?.name,
                            value: currentCompany?.companyID,
                        },
                        ...(currentCompany && {
                            officeAddress: {
                                street: currentCompany?.address?.street ?? '',
                                city: currentCompany?.address?.city ?? '',
                                isStreetPublic: true,
                                isCityPublic: true,
                            },
                        }),
                    },
                    {}
                );

                setTabs(usersTabsNames.slice(0, 4));
            }

            setCurrentStep(1);
            setBreadCrumbs([
                ...breadCrumbs,
                clickedUserID && foundUser
                    ? `${foundUser?.firstName} ${foundUser?.lastName}`
                    : t('createUser'),
            ]);
        },
        [breadCrumbs, company, dispatch, form, t]
    );

    useEffect(() => {
        if (userID) {
            onCreateOrEdit(userID);
        } else {
            setCurrentStep(0);
            setBreadCrumbs([t('manageUsers')]);
        }
    }, [userID]);

    const renderNextButton = () => {
        return (
            currentStep < tabs.length && (
                <Button
                    style={{ maxWidth: '100px' }}
                    theme={'primary'}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    icon={<ArrowLeft size={14} />}
                    direction={'left'}>
                    {t('next')}
                </Button>
            )
        );
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return <UsersManagement onCreate0rEdit={onCreateOrEdit} />;
            case 1:
                return <UsersStep1 editedUser={editedUser} />;
            case 2:
                return <UsersStep2 />;
            case 3:
                return <UsersStep3 />;
            case 4:
                return <UsersStep4 />;
            case 5:
                return <UsersStep5 />;
            case 6:
                return <UsersStep6 />;
        }
    };

    const handleSave = async () => {
        await form.trigger().then((value) => {
            if (!value) {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('mustFillMandatoryFields'),
                        header: {
                            title: t('save'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                            ],
                        },
                    })
                );
            } else {
                dispatch(
                    raiseModal({
                        isOpen: true,
                        content: t('areYouSureSaveChanges'),
                        header: {
                            title: t('save'),
                            icon: <ModalIcon theme={'ALERT'} />,
                        },
                        footer: {
                            buttons: [
                                { label: t('close'), theme: 'grey', callback: () => dispatch(closeModal()) },
                                {
                                    label: t('save'),
                                    theme: 'primary',
                                    callback: () => {
                                        confirmSave();
                                        dispatch(closeModal());
                                    },
                                },
                            ],
                        },
                    })
                );
            }
        });
    };

    const confirmSave = () => {
        const userForm = form.getValues();
        const user: User = buildFormUser(userForm, editedUser);

        createOrEditUser(user)
            .then(() => {
                SuccessToast(t('userSavedSuccessfully'));
                form.reset(userForm);
                // onStageReset();
            })
            .catch((e) => console.log(e));
    };

    const onStageReset = () => {
        setCurrentStep(0);
        setBreadCrumbs([t('manageUsers')]);
        navigate('/users');
    };

    const sendSmsDownloadAPI = async () => {
        await sendSmsDownload(userID);
    };

    return (
        <div className={'users'}>
            <FormProvider {...form}>
                <BreadCrumbs
                    breadCrumbTitle={t('manageUsers')}
                    icon={<Home width={13} height={13} color={'var(--primary)'} />}
                    breadCrumbs={breadCrumbs}
                    onBack={() =>
                        handleBack(() => {
                            onStageReset();
                            navigate('/users');
                        }, onStageReset)
                    }
                />
                {currentStep !== 0 && (
                    <div className={'steps-tabs'}>
                        <Tabs activeTab={currentStep} toggleTab={setCurrentStep} tabs={tabs} />
                        <span style={{ display: 'flex', gap: '12px' }}>
                            <Button
                                theme={'outline-primary'}
                                onClick={() => sendSmsDownloadAPI()}
                                icon={undefined}>
                                {t('sendSmsDownload')}
                            </Button>
                            <Button
                                theme={'outline-primary'}
                                onClick={() => {
                                    handleBack(() => {
                                        onStageReset();
                                        navigate('/users');
                                    }, onStageReset);
                                }}
                                icon={<ArrowRight size={14} />}>
                                {t('back')}
                            </Button>

                            <Button theme={'primary'} onClick={handleSave} icon={<Save size={14} />}>
                                {t('save')}
                            </Button>
                        </span>
                    </div>
                )}
                {renderStep()}
                {currentStep !== 0 && renderNextButton()}
            </FormProvider>
        </div>
    );
};
