import { FC, ReactElement, SVGProps } from 'react';
import { Home } from './components/home/Home';
import {
    Home as HomeIcon,
    Users as UsersIcon,
    ShoppingCart as CartIcon,
    Briefcase as CaseIcon,
    Package as OrdersIcon,
    Settings as ListsIcon,
    FileText,
} from 'react-feather';
import { AiOutlineDashboard } from 'react-icons/ai';
import { IconType } from 'react-icons';
import { Users } from './components/users/Users';
import { UserType } from './components/users/users.models';
import { Companies } from './components/companies/Companies';
import { Store } from './components/store/Store';
import { Orders } from './components/orders/Orders';
import { RouteObject } from 'react-router';
import { UsersStep1 } from './components/users/UsersStep1';
import { Settings } from './components/Settings/Settings';
import { Reports } from './components/reports/Reports';
import { OrderPage } from './components/orders/OrderPage';
import { Dashboard } from './components/dashboard/dashboard';

export type AppChildRoute = RouteObject & AppRoute;

export interface AppRoute {
    path: string;
    element: ReactElement | null;
    roles?: (UserType | undefined)[];
    description?: { icon: FC<SVGProps<SVGSVGElement>> | IconType; name: string };
    children?: AppChildRoute[];
}

export const ROUTES: RouteObject & AppRoute[] = [
    {
        roles: ['HR', 'admin'],
        path: 'home',
        description: { name: 'homePage', icon: HomeIcon },
        element: <Home />,
    },
    {
        roles: ['HR', 'admin'],
        path: 'companies',
        description: { name: 'companies', icon: CaseIcon },
        element: <Companies />,
        children: [
            {
                roles: ['HR', 'admin'],
                path: ':companyID',
                element: <Companies />,
            },
        ],
    },
    {
        roles: ['HR', 'admin'],
        path: 'users',
        description: { name: 'users', icon: UsersIcon },
        element: <Users />,
        children: [
            {
                roles: ['HR', 'admin'],
                path: ':userID',
                element: <UsersStep1 editedUser={undefined} />,
            },
        ],
    },
    {
        roles: ['HR', 'admin'],
        path: 'store',
        description: { name: 'store', icon: CartIcon },
        element: <Store />,
        children: [
            {
                roles: ['HR', 'admin'],
                path: 'product/:id',
                element: <Store />,
            },
            {
                roles: ['admin'],
                path: 'suppliers/:id',
                element: <Store />,
            },
            {
                roles: ['admin'],
                path: 'categories/:id',
                element: <Store />,
            },
        ],
    },
    {
        roles: ['admin'],
        path: 'orders',
        description: { name: 'orders', icon: OrdersIcon },
        element: <Orders />,
        children: [
            {
                roles: ['admin'],
                path: 'order/:order',
                element: <OrderPage onStageReset={() => {}} />,
            },
        ],
    },
    {
        roles: ['admin'],
        path: 'reports',
        description: { name: 'reports', icon: FileText },
        element: <Reports />,
    },
    {
        roles: ['HR', 'admin'],
        path: 'dashboard',
        description: { name: 'dashboard', icon: AiOutlineDashboard },
        element: <Dashboard />,
    },
    {
        roles: ['admin'],
        path: 'settings',
        description: { name: 'settings', icon: ListsIcon },
        element: <Settings />,
    },
    {
        roles: ['HR', 'admin'],
        path: '*',
        element: <Home />,
    },
];
