import { FC, useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Filters } from '../filters/Filters';
import StatisticsCard from './statistics_card';
import { getDashboardMetrics } from '../../api/dashboard.api';
import { DashboardForm, dashboardDefaultValues, dashboardRows, dashboardTabs } from './dashboard.models';
import { formatDate, getDashboardFilters, getDateRange } from './dashboard.utils';
import { Tabs } from '../tabs/Tabs';
import './dashboard.scss';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../store/users/UsersSelectors';
import { getCompanies } from '../../store/companies/companies.selectors';

export const Dashboard: FC = () => {
    const { t } = useTranslation();
    const [currentStep, setCurrentStep] = useState(0);
    const [currentTab, setCurrentTab] = useState(0);
    const currentUser = useSelector(getCurrentUser);
    const companies = useSelector(getCompanies);
    const form = useForm<DashboardForm>({
        defaultValues: dashboardDefaultValues,
        mode: 'onTouched',
    });

    const truncateToDay = (timestamp: number) => {
        const millisecondsPerDay = 86400000; // 24 * 60 * 60 * 1000
        return Math.floor(new Date(timestamp).getTime() / millisecondsPerDay) * millisecondsPerDay;
    };

    const { setValue, watch } = form;

    useEffect(() => {
        if (currentUser?.userType !== 'admin') {
            const currentCompany = companies.find((c) => c.companyID === currentUser?.companyID);
            if (currentCompany?.name) {
                form.setValue('companiesIDs', [
                    {
                        label: currentCompany.name,
                        value: currentCompany.companyID,
                    },
                ]);
            }
        }
    }, [companies, currentUser?.companyID, currentUser?.userType, form]);

    useEffect(() => {
        const { fromTimestamp, toTimestamp } = getDateRange(currentTab);
        if (currentTab !== -1) {
            setValue('fromTimestamp', fromTimestamp);
            setValue('toTimestamp', toTimestamp);
        }
    }, [currentTab, setValue]);

    const watchedFromTimestamp = watch('fromTimestamp');
    const watchedToTimestamp = watch('toTimestamp');

    useEffect(() => {
        if (watchedFromTimestamp && watchedToTimestamp) {
            const { fromTimestamp, toTimestamp } = getDateRange(currentTab);

            if (
                truncateToDay(watchedFromTimestamp) !== truncateToDay(fromTimestamp) ||
                truncateToDay(watchedToTimestamp) !== truncateToDay(toTimestamp)
            ) {
                setCurrentTab(-1);
            }
        }
    }, [watchedFromTimestamp, watchedToTimestamp, setCurrentTab]);

    const filters = form.watch();

    const [shouldFetch, setShouldFetch] = useState(true);

    const { data: dashboardMetrics, refetch } = useQuery(
        ['dashboardMetrics', filters],
        () =>
            getDashboardMetrics({
                companiesIDs: filters.companiesIDs,
                groupsIDs: filters.groupsIDs,
                departmentID: filters.departmentID,
                // userID: filters.userID,
                // gender: filters.gender,
                fromTimestamp: filters.fromTimestamp ? new Date(filters.fromTimestamp).getTime() : null,
                toTimestamp: filters.toTimestamp ? new Date(filters.toTimestamp).getTime() : null,
                // ageFromTimestamp: filters.ageFromTimestamp
                //     ? new Date(filters.ageFromTimestamp).getTime()
                //     : null,
                // ageToTimestamp: filters.ageToTimestamp ? new Date(filters.ageToTimestamp).getTime() : null,
                // gender: filters.gender?.value,
            }),
        {
            enabled: shouldFetch,
            onSettled: () => setShouldFetch(false),
        }
    );

    const onFilter = () => {
        setShouldFetch(true);
        refetch();
    };

    const renderTab = () => {
        // Filter out rows containing the 'script' tab for non-admin users
        const filteredDashboardRows = dashboardRows.filter(
            (row) => !row?.cards.some((card) => card.title === 'script') || currentUser?.userType === 'admin'
        );

        // Ensure currentStep is valid after filtering
        const adjustedCurrentStep = Math.min(currentStep, filteredDashboardRows.length - 1);

        return (
            <div>
                <Row height={'300px'}>
                    {filteredDashboardRows[adjustedCurrentStep]?.cards.map((card, cardIndex) => {
                        const isUniqueVisitorsBar =
                            card.chartType === 'bar' && card.title === 'uniqueVisitors';
                        const dateType = isUniqueVisitorsBar
                            ? t(dashboardMetrics?.data?.mixpanel?.dateType) || ''
                            : null;

                        return (
                            <Col
                                key={cardIndex}
                                sm={
                                    filteredDashboardRows[adjustedCurrentStep].columnSizes[cardIndex] || '12'
                                }>
                                <StatisticsCard
                                    transformData={card.transformData}
                                    title={
                                        <>
                                            {t(card.title)}
                                            {isUniqueVisitorsBar && ` - ${dateType}`}
                                            <br />
                                            <span className="small-text">
                                                {formatDate(filters.fromTimestamp!)} -{' '}
                                                {formatDate(filters.toTimestamp!)}
                                            </span>
                                        </>
                                    }
                                    data={card.transformData(
                                        dashboardMetrics,
                                        filters.fromTimestamp,
                                        filters.toTimestamp
                                    )}
                                    chartType={card.chartType}
                                    chartProps={card.chartProps}
                                />
                            </Col>
                        );
                    })}
                </Row>
            </div>
        );
    };

    return (
        <Container fluid>
            <FormProvider {...form}>
                <div className="dashboard">
                    <Tabs
                        activeTab={currentStep + 1}
                        toggleTab={(step) => setCurrentStep(step - 1)}
                        tabs={dashboardTabs.filter(
                            (_, index) =>
                                !dashboardRows[index]?.cards.some(
                                    (card) => card.title === 'script' && currentUser?.userType !== 'admin'
                                )
                        )}
                        className="only-two-active"
                    />
                    <div className="filters">
                        <Filters
                            filters={getDashboardFilters(onFilter, currentStep, currentTab, setCurrentTab, t)}
                            clearFilters={() => {
                                form.reset();
                                setCurrentTab(0);
                            }}
                            title={t('filterBy')}
                            initialOpen
                        />
                    </div>
                    {dashboardMetrics && renderTab()}
                </div>
            </FormProvider>
        </Container>
    );
};
