import { FC } from 'react';
import { Button } from '../form/buttons/Button';
import { Modal } from '../modals/Modal';
import { t } from 'i18next';
import { Copy, X } from 'react-feather';

interface zeroVisitModalProps {
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    openModal: boolean;
    usersWithZeroVisit: any;
}

const ZeroVisitModal: FC<zeroVisitModalProps> = ({ setOpenModal, openModal, usersWithZeroVisit }) => {
    return (
        <Modal onClose={() => setOpenModal(false)} isOpen={openModal}>
            <Button
                onClick={() => setOpenModal(false)}
                theme="primary"
                style={{ width: '8%' }}
                className="zero-visit-close"
                icon={<X />}></Button>
            <div className="zero-visit-div">
                <div className="zero-visit-wrapper">
                    <h3 className="zero-visit-title">
                        {t('zeroVisitListTitle')} ({usersWithZeroVisit.length})
                    </h3>
                    <div style={{ maxHeight: '400px', overflowY: 'scroll' }}>
                        {usersWithZeroVisit.map((user: any, index: any) => (
                            <div key={index}>
                                <p>
                                    {user.firstName} {user.lastName} - {user.phone?.value}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
                <Button
                    onClick={() => {
                        const allUserDetails = usersWithZeroVisit
                            .map((user: any) => `${user.firstName} ${user.lastName} - ${user.phone?.value}`)
                            .join('\n');
                        navigator.clipboard.writeText(allUserDetails);
                    }}
                    theme="primary"
                    style={{ width: '10%' }}
                    icon={<Copy />}></Button>
            </div>
        </Modal>
    );
};

export default ZeroVisitModal;
